<template>
  <section :class="`cardLoading`" :style="`--columns:${repeatColumns}`">
    <div class="cardLoading__firstSection">
      <div class="cardLoading__contentCheck">
        <div class="cardLoading__btnHistory skeleton"></div>
        <div class="cardLoading__checkbox skeleton"></div>
      </div>
      <div class="cardLoading__contentInfo">
        <div class="cardLoading__contentInfo">
          <div class="cardLoading__headerInfo">
            <div class="cardLoading__contentItems">
              <div class="cardLoading__recordName skeleton"></div>
              <span class="cardLoading__recordCity skeleton"></span>
            </div>
            <div class="cardLoading__contentItems">
              <span class="cardLoading__detail skeleton"></span>
              <span class="cardLoading__detail skeleton"></span>
            </div>
          </div>
          <div class="cardLoading__contentItems">
            <div class="cardLoading__icon skeleton" v-for="idx in 3"></div>
            <div class="cardLoading__feature skeleton" v-for="idx in 3"></div>
          </div>
          <span class="cardLoading__registerDate skeleton"></span>
        </div>
        <div class="cardLoading__secSection" v-if="screenWidth <= 860">
          <div class="cardLoading__photoImg skeleton" v-for="idx in skeletonImages"></div>
        </div>
      </div>
    </div>
    <div class="cardLoading__secSection" v-if="screenWidth > 860">
      <div class="cardLoading__photoImg skeleton" v-for="idx in skeletonImages"></div>
    </div>
    <div class="cardLoading__threeSection" v-if="screenWidth >= 1160">
      <div class="cardLoading__contentMessage skeleton"></div>
      <div class="cardLoading__contentBtns" v-if="screenWidth > 1625">
        <div class="cardLoading__contentIcon skeleton"></div>
        <div class="cardLoading__contentIcon skeleton"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    screenWidth() {
      return this.$store.state.screen.width;
    },
    skeletonImages() {
      const breakpoints = [
        { maxWidth: 370, value: 1 },
        { maxWidth: 495, value: 2 },
        { maxWidth: 620, value: 3 },
        { maxWidth: 860, value: 4 },
        { maxWidth: 1160, value: 3 },
        { maxWidth: 1370, value: 2 },
        { maxWidth: 1600, value: 3 },
      ];

      let valueReturn = 4;

      breakpoints.some((breakpoint) => {
        const isLessWidth = this.screenWidth < breakpoint.maxWidth;

        if (isLessWidth) {
          valueReturn = breakpoint.value;
        }
        return isLessWidth;
      });

      return valueReturn;
    },
    repeatColumns() {
      const breakpoints = [
        { maxWidth: 860, value: 1 },
        { maxWidth: 1160, value: 2 },
      ];

      let valueReturn = 3;

      breakpoints.some((breakpoint) => {
        const isLessWidth = this.screenWidth < breakpoint.maxWidth;

        if (isLessWidth) {
          valueReturn = breakpoint.value;
        }
        return isLessWidth;
      });

      return valueReturn;
    },
  },
};
</script>

<style lang="scss">
.cardLoading {
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  width: 100%;
  min-height: 180px;
  gap: 15px;
  padding: 15px;
  background: #f9f8fd;
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 10px;
  &__firstSection {
    position: relative;
    @include Flex(row, flex-start);
    width: 100%;
    gap: 15px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 1px;
      height: 90%;
      background-color: #cccccc;
    }
  }
  &__contentCheck {
    @include Flex(row);
  }
  &__btnHistory {
    position: absolute;
    top: 0;
    height: 20px;
    width: 20px;
    border-radius: 15px;
  }
  &__checkbox {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }
  &__contentInfo {
    @include Flex(column, space-between, flex-start);
    gap: 8px;
    width: 100%;
    height: 100%;
    min-height: 150px;
  }
  &__headerInfo {
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    gap: 8px;
  }
  &__contentItems {
    @include Flex(row, flex-start);
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__recordName {
    height: 20px;
    width: 100%;
    max-width: 250px;
    border-radius: 10px;
  }
  &__recordCity {
    height: 23px;
    width: 80px;
    border-radius: 17px;
  }
  &__detail {
    height: 18px;
    width: 85px;
    border-radius: 50px;
  }
  &__icon {
    height: 23px;
    width: 23px;
    border-radius: 50px;
  }
  &__registerDate {
    height: 14px;
    width: 100%;
    max-width: 270px;
    border-radius: 17px;
  }
  &__feature {
    height: 23px;
    width: 55px;
    border-radius: 17px;
  }
  &__secSection {
    @include Flex(row, flex-start);
    height: 100%;
    width: 100%;
    max-width: 500px;
    gap: 20px;
  }
  &__photoImg {
    min-height: 180px;
    height: 100%;
    width: 105px;
    border-radius: 12px;
    box-shadow: 0px 0px 4.942477703094482px 0px #0000004d;
  }
  &__threeSection {
    @include Flex(row, space-between, flex-start);
    width: 100%;
    gap: 15px;
  }
  &__contentMessage {
    height: 80px;
    width: 100%;
    max-width: 429px;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
  }
  &__contentBtns {
    @include Flex(row);
    gap: 10px;
  }
  &__contentIcon {
    @include Flex(row);
    height: 27px;
    width: 27px;
    border-radius: 50%;
  }
}
</style>
